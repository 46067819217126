export const getRandomNumberInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const generateSimilarPathTo = path => {
  const pointArr = path.split(/(?=[MQT])/);
  let newPointArr = [];
  for (let i = 0; i < pointArr.length; i++) {
    let newPoint = pointArr[i];
    if (pointArr[i].includes("Q")) {
      const curveCommand = pointArr[i].split(" ");
      const contrPointX = parseInt(curveCommand[1]) + getRandomNumberInRange(10, 30);
      newPoint = `Q ${contrPointX} ${curveCommand[2]} ${curveCommand[3]} ${curveCommand[4]}`;
    }
    newPointArr.push(newPoint);
  }
  return newPointArr.join();
};
