import React, { useEffect, useCallback, useRef } from 'react';
import { useIntersection, useWindowSize } from 'react-use';
import Img from "gatsby-image";

import gsap, { Power2, Elastic } from 'gsap';

import { generateSimilarPathTo } from '../../utils';

import './style.css';

const paths = [
  {
    id: 1,
    pathStrings: {
      initialPath:
        "",
      targetPath:
        "M 39 557 Q -5 511 36 462 T 37 391 T 33 332 T 46 264 T 71 200 T 141 197 T 204 185 T 274 165 T 286 205 T 302 254 T 335 314 T 299 373 T 308 446 T 322 511 T 289 567 T 151 609 C 108 604 68 589 39 557 Z",
    }
  },
  {
    id: 2,
    pathStrings: {
      initialPath:
        "M 208 643 Q 150 753 211 733 T 261 838 T 264 785 T 264 798 T 240 892 T 174 941 T 96 1029 T -8 1087 T -42 984 T -37 813 T -61 694 T -2 655 T 65 649 T 209 641 Z",
      targetPath:
        "M 39 557 Q -5 511 36 462 T 37 391 T 33 332 T 46 264 T 71 200 T 141 197 T 204 185 T 274 165 T 286 205 T 302 254 T 335 314 T 299 373 T 308 446 T 322 511 T 289 567 T 151 609 C 108 604 68 589 39 557 Z",
    }
  },
  {
    id: 3,
    pathStrings: {
      initialPath:
        "M 58 647 Q 112 450 251 642 T 268 785 T 239 825 T 264 918 T 240 1012 T 174 1061 T 287 1127 T 154 1249 T -5 1219 T 71 1074 T 4 1001 T 23 924 T 20 813 T 105 801 T 38 699 T 52 651 Z",
      targetPath:
        "M 39 557 Q -5 511 36 462 T 37 391 T 33 332 T 46 264 T 71 200 T 141 197 T 204 185 T 274 165 T 286 205 T 302 254 T 335 314 T 299 373 T 308 446 T 322 511 T 289 567 T 151 609 C 108 604 68 589 39 557 Z",
    }
  },
  {
    id: 4,
    pathStrings: {
      initialPath:
        "M 158 585 Q 297 558 289 681 T 178 776 T 239 855 T 264 948 T 240 1042 T 174 1091 T 287 1157 T 154 1279 T -5 1249 T 71 1104 T 4 1031 T 23 954 T 20 843 T 59 754 T 62 624 T 157 585 Z",
      targetPath:
        "M 39 557 Q -5 511 36 462 T 37 391 T 33 332 T 46 264 T 71 200 T 141 197 T 204 185 T 274 165 T 286 205 T 302 254 T 335 314 T 299 373 T 308 446 T 322 511 T 289 567 T 151 609 C 108 604 68 589 39 557 Z",
    }
  },
  {
    id: 5,
    pathStrings: {
      initialPath:
        "M 208 643 Q 150 753 211 733 T 261 838 T 264 785 T 264 798 T 240 892 T 174 941 T 96 1029 T -8 1087 T -42 984 T -37 813 T -61 694 T -2 655 T 65 649 T 209 641 Z",
      targetPath:
        "M 39 557 Q -5 511 36 462 T 37 391 T 33 332 T 46 264 T 71 200 T 141 197 T 204 185 T 274 165 T 286 205 T 302 254 T 335 314 T 299 373 T 308 446 T 322 511 T 289 567 T 151 609 C 108 604 68 589 39 557 Z",
    }
  },
];

const text = [
  {},
  {
    title: "All the small things...",
    description: "Quando sono con te anche le cose più semplici creano momenti indimenticabili"
  },
  {
    title: "Come mamma ci ha fatto",
    description: "Con te posso essere me stesso, anche un po' babbo, nerd, rompipalle"
  },
  {
    title: "Avengers",
    description: "Siamo una squadra vincente, anche senza gemme dell'infinito"
  },
  {
    title: "Hop on Hop off",
    description: "Sei la perfetta compagna d'avventure, non vedo l'ora di tornare ad esplorare il mondo insieme"
  },
  {
    title: "Non piangere pls",
    description: "Sai regalarmi momenti che porterò sempre con me"
  },
]

const Section = ({ id, backgroundColor, color, image }) => {
  const currentSectionPath = paths.find(p => p.id === id);
  const { targetPath } = currentSectionPath.pathStrings;

  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '200px',
    threshold: 0.6
  });

  const {width, height} = useWindowSize();

  useEffect(() => {
    const newPath = generateSimilarPathTo(targetPath);
    gsap.to(`#path_${id}`, {
      duration: 2,
      attr: {
        d: newPath,
      },
      yoyo: true,
      repeat: -1,
      ease: Power2.easeInOut,
    });
  }, [id, targetPath]);

  const fadeOut = useCallback(() => {
    gsap.to(`#section${id}Text`, {
      duration: 0.5,
      opacity: 0,
      ease: Power2.easeInOut
    });
    gsap.to(`#path_${id}`, {
      duration: 1,
      delay: 1,
      scale: 0,
      ease: Elastic.easeOut.config(0.2, 0.2)
    });
  }, [id]);

  const fadeIn = useCallback(() => {
    gsap.to(`#section${id}Text`, {
      duration: 0.5,
      opacity: 1,
      ease: Power2.easeInOut
    });
    gsap.to(`#path_${id}`, {
      duration: 1,
      delay: 1,
      scaleX: Math.min(width / 360, 2.7),
      scaleY: height / 640,
      ease: Elastic.easeOut.config(0.2, 0.2)
    });
  }, [id, height, width]);

  useEffect(() => {
    if (intersection && intersection.intersectionRatio < 0.6) {
      fadeOut();
    } else {
      fadeIn();
    }
  }, [intersection, fadeIn, fadeOut])

  const style = { 
    backgroundColor, 
    zIndex: id, 
    color: color ? color : '#333333' 
  }

  return (
    <section className="section" style={style}>
      <div className="section__text" id={`section${id}Text`}>
        <h1 className="section__title">{text[id].title}</h1>
        <p className="section__description">{text[id].description}</p>
      </div>
      <div className="section__image-wrapper" ref={intersectionRef}>
        <Img fluid={image} imgStyle={{ clipPath: `url(#${id})` }} style={{ height: '100vh' }} />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="section__svg" >
        <defs>
          <clipPath id={id}>
            <path d={targetPath} id={`path_${id}`} />
          </clipPath>
        </defs>
      </svg>
    </section>
  )
};

export default Section;