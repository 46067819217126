import React, { useRef } from 'react';
import { useIntersection } from 'react-use';

import TimeCounter from '../TimeCounter';
import Typewriter from 'typewriter-effect';
import './style.css';

const words = [
  'Together',
  'Netflix and Chill',
  'Eating Sushi',
  'Gin and Tonic',
  'Loving you'
];

const FooterText = () => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4
  });

  return (
  <footer className="footer" ref={intersectionRef}>
    {intersection && intersection.intersectionRatio > 0.4 &&
    (<>
      <Typewriter 
        options={{
            strings: words, 
            autoStart: true, 
            loop: true,
            cursor: '_',
            wrapperClassName: 'footer__typewriter',
            cursorClassName: 'footer__typewriter-cursor'
      }}/>
      <h1 className="footer__since">Since 23.06.2019</h1>
      <hr className="footer__separator" />
      <TimeCounter />
    </>)
    }
  </footer>
  )
};

export default FooterText;