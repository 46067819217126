import React, { useEffect, useState } from 'react';
import moment from 'moment';

const TimeCounter = (dateFrom, timeFormat) => {
  const [timePassed, setTimePassed] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0})

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const dayFrom = moment("23 06 2019", "DD MM YYYY");
      
      let days = now.diff(dayFrom, 'days');
      let hours = now.hours() - 18;
      let minutes = now.minutes() - 30;
      let seconds = now.seconds();

      if(hours < 0) {
        hours += 24;
        days -= 1;
      }
      if(minutes < 0) {
        minutes += 60;
        hours -= 1;
      }

      setTimePassed({ days, hours, minutes, seconds });
    }, 1000);

    return(() => {
      clearInterval(interval);
    })
  }, [])

  return (
    <>
      <h3 style={{ fontSize: '2rem' }}>{`${timePassed.days} Days`}</h3>
      <h3>
        {`
          ${timePassed.hours} Hours 
          ${timePassed.minutes} Minutes
          ${timePassed.seconds} Seconds
        `}
      </h3>
    </>
  )
};

export default TimeCounter