import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Section from "../Section";
import Icon from '../../assets/down-arrow.svg';
import Footer from '../Footer';

import './style.css';

const sections = [
  {
    id: 1,
    backgroundColor: "#ffe7db",
    color: "#333333"
  },
  {
    id: 2,
    backgroundColor: "#4278f5",
    color: "#f1f1f1"
  },
  {
    id: 3,
    backgroundColor: "#ffae36",
    color: "#333333"
  },
  {
    id: 4,
    backgroundColor: "#5cffb4",
    color: "#333333"
  },
  {
    id: 5,
    backgroundColor: "#e33636",
    color: "#f1f1f1"
  },
]

const App = () => {
  const data = useStaticQuery(graphql`
    query Images {
      desktop: allFile(filter: {relativePath: {regex: "/(desktop.png)/"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 600, pngQuality: 100) {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
          }
        }
      },
      mobile: allFile(filter: {relativePath: {regex: "/(mobile.png)/"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 960, pngQuality: 100) {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }  
  `);

  const mobileImages = data.mobile.edges.map(img => img.node);
  const desktopImages = data.desktop.edges.map(img => img.node);

  const findIndex = (arr, id) => {
    return arr.findIndex(i => i.base.includes(id));
  }

  const getSection = (id, backgroundColor, color) => (
    <Section 
        id={id}
        key={`section${id}`}
        backgroundColor={backgroundColor}
        color={color}
        image={
          [
            mobileImages[findIndex(mobileImages, id)].childImageSharp.fluid,
            {
              ...desktopImages[findIndex(desktopImages, id)].childImageSharp.fluid, 
              media: '(min-width: 768px)'
            }
          ]
        }/>
  );

  return (
    <>
      <div className="app__hero">
        <h1 className="app__title">Top 5 reasons why I love you</h1>
        <Icon className="app__icon" />
      </div>
      { sections.map(({ id, backgroundColor, color }) => getSection(id, backgroundColor, color))}
      <Footer />
    </>
  );
}

export default App;
